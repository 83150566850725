import React from 'react';

// import styled from 'styled-components';
// import media from 'styles/media';
// import colors from 'styles/colors';
// import text from 'styles/text';

import PrimeNavResp from './PrimeNavResp';
import SecondaryNavResp from './SecondaryNavResp';

const ResponsiveNav = ({slug}) => {
  return (
    <>
      <PrimeNavResp slug={slug}/>
      <SecondaryNavResp/>
    </>
  )
}

export default ResponsiveNav


// const Wrapper = styled.div`
//   /* overflow: hidden; */
// `