const colors = {
  mediumPurple: "#583F99",
  sysLightPurple: "#b19cf1",
  darkPurple: "#471147",
  primaryPurple: "#3D2562",
  mediumPurple: "#583F99",
  bgSecondary: "#3D2562",
  bgFill: "#3D2562",
  purple500: "#6A2C6A",
  purple400: "#8B5B8B",
  purple300: "#AC8AAC",
  purple200: "#CDB9CD",
  purple100: "#EEE8EE",
  lightPurpleGrey: `#F5F4F7`,
  mediumPurpleGrey:`#ECE9EF`,
  lightPurple: `#7E5FDD`,
  darkOrange: "#CC4800",
  primaryOrange: "#ff5100",
  orange500: "#FF612A",
  orange400: "#FF854d",
  orange300: "#FFAD80",
  orange200: "#FFCBB2",
  orange100: "#FFEEE5",
  grey800: "#191D1E",
  grey750: "#292B2C",
  grey700: "#38393A",
  grey600: "#5E5F61",
  grey550: "#808085",
  grey500: "#838587",
  grey400: "#A8ABAE",
  grey300: "#BBBEC1",
  grey200: "#C2C5C7",
  grey100: "#CFD2D4",
  grey75: "#DDDFE0",
  grey50: "#EBECEC",
  grey25: "#F6F7F7",
  white: "#FFFFFF",
  black: "#000000",
  purpleGradient:
    "linear-gradient(270deg, #945AEE 0%, rgba(148, 90, 238, 0.4) 100%);",
  orangeGradient:
    "linear-gradient(270deg, #FF5100 0%, rgba(255, 81, 0, 0.4) 100%);",
  greyGradient: "linear-gradient(270deg, #000000 0%, rgba(0, 0, 0, 0.4) 100%);",
  lightPurpleGradient: "linear-gradient(180deg, #F5F4F7 0%, #E8E0EB 100%);",
  darkPurpleGradient: "linear-gradient(180deg, #583F99 0%, #3D2562 100%);",
}

export default colors
