import React, { useContext } from "react";

// import styled from 'styled-components';
// import media from 'styles/media';
// import colors from 'styles/colors';
// import text from 'styles/text';

import { ScreenContext } from "../components/Providers/Screen"
import DoubleNav from "./DoubleNav";
import ResponsiveNav from "./ResponsiveNav/ResponsiveNav";
import { useDocumentReady } from "utils/pageReady"


const NavSwitcher = ({slug}) => {
  const { mobile, tablet } = useContext(ScreenContext)
  useDocumentReady()
  
  return (
    <>
      {mobile || tablet ? <ResponsiveNav slug={slug}/> : <DoubleNav slug={slug}/>}
    </>
  )
}

export default NavSwitcher