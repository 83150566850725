import React from "react"
import "styles/fonts/typography.css"
import "styles/fonts/reset.css"

import Providers from "./src/components/Providers/Index"
import Layout from 'components/Layout'

export const wrapRootElement = ({ element }) => {
  return <Providers>{element}</Providers>
}

