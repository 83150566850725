import React, { useEffect } from "react"

import gsap from "gsap"
import styled from "styled-components"
import media from "styles/media"
import colors from "styles/colors"
// import text from 'styles/text';
import WhiteStar from "images/Loader/WhiteStar.png"

const Loader = () => {
  useEffect(() => {
    const body = document.body

    if (sessionStorage.length === 0) {
      const tl = gsap.timeline({})

      tl.set(body, { overflow: "hidden" })
        .set("#layoutWrapper", { opacity: 1 })
        .set("#loaderWrapper", { display: "flex" })
        .to("#loaderStar", {
          opacity: 1,
          scale: 2,
          delay: 0.55,
          duration: 0.35,
        })
        .to("#loaderStar", { opacity: 0, scale: 1, duration: 0.15 })
        .to("#loaderStar", { opacity: 1, scale: 2, duration: 0.35 })
        .to("#loaderStar", { opacity: 0, duration: 0.35 })
        .set("#loaderWrapper", { "background-color": "unset" })
        .to("#loaderUpper", { yPercent: -100, duration: 0.5 })
        .to("#loaderLower", { yPercent: 100, duration: 0.5 }, "<")
        .to("#loaderWrapper", { display: "none" })
        .set(body, { overflow: "scroll" })

      return () => {
        tl.kill()
      }
    } else {
      gsap.set("#loaderWrapper", { display: "none" })
      gsap.set("#layoutWrapper", { opacity: 1 })
    }
  }, [])

  return (
    <Wrapper id="loaderWrapper">
      <UpperDiv id="loaderUpper" />
      <Star id="loaderStar" src={WhiteStar} alt="loader star" />
      <LowerDiv id="loaderLower" />
    </Wrapper>
  )
}

export default Loader

const Star = styled.img`
  position: relative;
  display: flex;
  align-self: center;
  opacity: 0;
  z-index: 10001;
  width: 5vw;
  height: 5vw;

  ${media.fullWidth} {
    width: 72px;
    height: 72px;
  }

  ${media.tablet} {
    width: 7.031vw;
    height: 7.031vw;
  }

  ${media.mobile} {
    width: 16.822vw;
    height: 16.822vw;
  }
`
const LowerDiv = styled.div`
  position: absolute;
  background-color: ${colors.primaryPurple};
  width: 100%;
  bottom: 0;
  height: 50vh;
`
const UpperDiv = styled.div`
  position: absolute;
  background-color: ${colors.primaryPurple};
  width: 100%;
  height: 50vh;
`
const Wrapper = styled.div`
  display: flex;
  position: absolute;
  justify-content: center;
  display: none;
  background-color: ${colors.primaryPurple};
  z-index: 10000;
  width: 100vw;
  height: 100vh;
`
