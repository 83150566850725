import React, { useEffect, useState } from "react"
import Helmet from "react-helmet"
import Footer from "./Footer"
import styled from "styled-components"

import fav1 from "images/Favicons/favicon-180x180.png"
import fav2 from "images/Favicons/favicon-32x32.png"
import fav3 from "images/Favicons/favicon-16x16.png"
import favicon from "images/Favicons/favicon.ico"
import SysBanner from "./SysBanner"
import NavSwitcher from "./NavSwitcher"
import Loader from "components/Loader"

const Layout = ({ children, isIndex, hidden, hideFooter, hideNav, slug }) => {
  const [domain, setDomain] = useState("printerlogic.com")
  const [language, setLanguage] = useState("en")
  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    if (document.getElementById("mktoForms")) {
      setIsLoaded(true)
    }
  }, [])

  useEffect(() => {
    let url = window.location.href
    console.log("URL:", url)

    let a = document.createElement("a")
    a.href = url
    let queryString = a.search
    console.log("Query String:", queryString)

    let utmParams = new URLSearchParams(queryString)
    utmParams.forEach(function (value, key) {
      console.log("Setting cookie:", key, value)
      document.cookie = key + "=" + value + ";path=/"
    })

    if (isLoaded) {
      console.log("isLoaded is true, setting up MktoForms2.whenReady")
      window.MktoForms2.whenReady(function (form) {
        console.log("MktoForms2.whenReady callback entered")
        let fields = form.getFields()
        console.log("Form fields:", fields)
        fields.forEach(function (field) {
          console.log(
            "Form field name:",
            field.name,
            "Field type:",
            field.element.type
          )
          if (field.element.type === "hidden") {
            let cookieValue = getCookie(field.name.toLowerCase())
            console.log(
              "Checking cookie for field:",
              field.name,
              "Cookie Value:",
              cookieValue
            )
            if (cookieValue) {
              console.log(
                "Setting field value:",
                field.name,
                "to:",
                cookieValue
              )
              field.val(cookieValue)
            }
          }
        })
      })
    } else {
      console.log("isLoaded is false, MktoForms2.whenReady will not be set up")
    }

    function getCookie(name) {
      let value = "; " + document.cookie
      let parts = value.split("; " + name + "=")
      if (parts.length === 2) return parts.pop().split(";").shift()
    }

    function checkSubdomain(url) {
      const domainMappings = {
        "fr.printerlogic.com": {
          domain: "fr.printerlogic.com",
          language: "fr",
        },
        "printerlogic.com": { domain: "printerlogic.com", language: "en" },
        "de.printerlogic.com": {
          domain: "de.printerlogic.com",
          language: "de",
        },
      }

      let parsedUrl = new URL(url)
      let hostname = parsedUrl.hostname

      const mapping = domainMappings[hostname]
      if (mapping) {
        setDomain(mapping.domain)
        setLanguage(mapping.language)
      } else {
        return
      }
    }

    checkSubdomain(window.location.href)
  }, [])

  return (
    <LayoutContainer id="layoutWrapper">
      <Helmet>
        <html lang={`${language}`} />
        <title>PrinterLogic</title>
        <meta
          name="description"
          content="PrinterLogic's Enterprise Print Management software allows IT professionals to simplify printer driver management and empower end users."
        />
        <meta content="https://www.printerlogic.com" itemProp="url" />
        <meta
          content="PrinterLogic – Enterprise Print Management Software"
          itemProp="name"
        />
        <meta
          content={hidden ? "noindex, nofollow" : "index,follow"}
          name="robots"
        />
        <meta
          content="PrinterLogic Team – www.printerlogic.com/careers"
          name="author"
        />
        <meta
          content="width=device-width, initial-scale=1.0, maximum-scale=10.0, user-scalable=1"
          media="(device-height: 568px)"
          name="viewport"
        />
        <meta content="True" name="HandheldFriendly" />
        <meta content="yes" name="apple-mobile-web-app-capable" />
        <meta content="black" name="apple-mobile-web-app-status-bar-style" />
        <meta content="PrinterLogic" name="apple-mobile-web-app-title" />
        <link rel="preconnect" href="https://dev.visualwebsiteoptimizer.com" />
        <link rel="apple-touch-icon" sizes="180x180" href={fav1} />
        <link rel="icon" type="image/png" sizes="32x32" href={fav2} />
        <link rel="icon" type="image/png" sizes="16x16" href={fav3} />
        <link rel="shortcut icon" href={favicon} />

        <link
          rel="canonical"
          href={
            slug === "index"
              ? `https://${domain}/`
              : `https://${domain}/${slug}/`
          }
        />

        <link
          rel="alternate"
          href={`https://www.printerlogic.com/${slug}/`}
          hreflang="en"
        />
        <link
          rel="alternate"
          href={`https://de.printerlogic.com/${slug}/`}
          hreflang="de"
        />
        <link
          rel="alternate"
          href={`https://fr.printerlogic.com/${slug}/`}
          hreflang="fr"
        />
        <link
          rel="alternate"
          href={`https://www.printerlogic.com/${slug}/`}
          hreflang="x-default"
        />

        <meta name="apple-mobile-web-app-title" content="PrinterLogic" />
        <meta name="application-name" content="PrinterLogic" />
        <meta content="#3D2562" name="theme-color" />
        <meta
          name="google-site-verification"
          content="JGPPAsY4zG6gpsChh8cpZiGOb1KGQvrdHeSJXtN9ROY"
        />
        <meta
          name="google-site-verification"
          content="Rg7KvJh8mynuzgrvebCOVeMZvP4oNc1I6JauRv_3vpU"
        />
        {/* VWO Snippet start*/}
        <script type="text/javascript">
          {`
        window._vwo_code || (function() {
          var account_id = 827254,
          version = 2.1,
          settings_tolerance = 2000,
          hide_element = 'body',
          hide_element_style = 'opacity:0 !important;filter:alpha(opacity=0) !important;background:none !important',
          f = false, w = window, d = document, v = d.querySelector('#vwoCode'), cK = '_vwo_' + account_id + '_settings', cc = {};
          
          try {
            var c = JSON.parse(localStorage.getItem('_vwo_' + account_id + '_config'));
            cc = c && typeof c === 'object' ? c : {};
          } catch(e) {}
          
          var stT = cc.stT === 'session' ? w.sessionStorage : w.localStorage;
          code = {
            use_existing_jquery: function() {
              return typeof use_existing_jquery !== 'undefined' ? use_existing_jquery : undefined;
            },
            library_tolerance: function() {
              return typeof library_tolerance !== 'undefined' ? library_tolerance : undefined;
            },
            settings_tolerance: function() {
              return cc.sT || settings_tolerance;
            },
            hide_element_style: function() {
              return '{' + (cc.hES || hide_element_style) + '}';
            },
            hide_element: function() {
              if (performance.getEntriesByName('first-contentful-paint')[0]) {
                return '';
              }
              return typeof cc.hE === 'string' ? cc.hE : hide_element;
            },
            getVersion: function() {
              return version;
            },
            finish: function(e) {
              if (!f) {
                f = true;
                var t = d.getElementById('_vis_opt_path_hides');
                if (t) t.parentNode.removeChild(t);
                if (e) (new Image).src = 'https://dev.visualwebsiteoptimizer.com/ee.gif?a=' + account_id + e;
              }
            },
            finished: function() {
              return f;
            },
            addScript: function(e) {
              var t = d.createElement('script');
              t.type = 'text/javascript';
              if (e.src) {
                t.src = e.src;
              } else {
                t.text = e.text;
              }
              d.getElementsByTagName('head')[0].appendChild(t);
            },
            load: function(e, t) {
              var i = this.getSettings(), n = d.createElement('script'), r = this;
              t = t || {};
              if (i) {
                n.textContent = i;
                d.getElementsByTagName('head')[0].appendChild(n);
                if (!w.VWO || VWO.caE) {
                  stT.removeItem(cK);
                  r.load(e);
                }
              } else {
                var o = new XMLHttpRequest();
                o.open('GET', e, true);
                o.withCredentials = !t.dSC;
                o.responseType = t.responseType || 'text';
                o.onload = function() {
                  if (t.onloadCb) {
                    return t.onloadCb(o, e);
                  }
                  if (o.status === 200) {
                    _vwo_code.addScript({ text: o.responseText });
                  } else {
                    _vwo_code.finish('&e=loading_failure:' + e);
                  }
                };
                o.onerror = function() {
                  if (t.onerrorCb) {
                    return t.onerrorCb(e);
                  }
                  _vwo_code.finish('&e=loading_failure:' + e);
                };
                o.send();
              }
            },
            getSettings: function() {
              try {
                var e = stT.getItem(cK);
                if (!e) {
                  return;
                }
                e = JSON.parse(e);
                if (Date.now() > e.e) {
                  stT.removeItem(cK);
                  return;
                }
                return e.s;
              } catch(e) {
                return;
              }
            },
            init: function() {
              if (d.URL.indexOf('__vwo_disable__') > -1) return;
              var e = this.settings_tolerance();
              w._vwo_settings_timer = setTimeout(function() {
                _vwo_code.finish();
                stT.removeItem(cK);
              }, e);
              var t;
              if (this.hide_element() !== 'body') {
                t = d.createElement('style');
                var i = this.hide_element(), n = i ? i + this.hide_element_style() : '', r = d.getElementsByTagName('head')[0];
                t.setAttribute('id', '_vis_opt_path_hides');
                v && t.setAttribute('nonce', v.nonce);
                t.setAttribute('type', 'text/css');
                if (t.styleSheet) t.styleSheet.cssText = n;
                else t.appendChild(d.createTextNode(n));
                r.appendChild(t);
              } else {
                t = d.getElementsByTagName('head')[0];
                var n = d.createElement('div');
                n.style.cssText = 'z-index: 2147483647 !important;position: fixed !important;left: 0 !important;top: 0 !important;width: 100% !important;height: 100% !important;background: white !important;';
                n.setAttribute('id', '_vis_opt_path_hides');
                n.classList.add('_vis_hide_layer');
                t.parentNode.insertBefore(n, t.nextSibling);
              }
              var o = 'https://dev.visualwebsiteoptimizer.com/j.php?a=' + account_id + '&u=' + encodeURIComponent(d.URL) + '&vn=' + version;
              if (w.location.search.indexOf('_vwo_xhr') !== -1) {
                this.addScript({ src: o });
              } else {
                this.load(o + '&x=true');
              }
            }
          };
          w._vwo_code = code;
          code.init();
        })();
      `}
        </script>
        {/* VWO Snippet end */}
        {/* Intercom Snippet */}
        <script>
          {` window.intercomSettings = {
              api_base: "https://api-iam.intercom.io",
              app_id: "h87qerzy"
            };`}
        </script>
        <script>
          {` // We pre-filled your app ID in the widget URL: 'https://widget.intercom.io/widget/h87qerzy'
          (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/h87qerzy';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();`}
        </script>
        {/* CookieYes Snippet */}
        <script
          id="cookieyes"
          type="text/javascript"
          src="https://cdn-cookieyes.com/client_data/4924a0671e9bfa11b037b945/script.js"
        ></script>
        <script
          type="text/javascript"
          src="https://info.printerlogic.com/js/forms2/js/forms2.min.js"
        ></script>
        {/* Munchkin Code Snippet */}
        <script type="text/javascript">
          {`
          (function() {
            var didInit = false;
            function initMunchkin() {
              if(didInit === false) {
                didInit = true;
                Munchkin.init('338-HTA-134');
              }
            }
            var s = document.createElement('script');
            s.type = 'text/javascript';
            s.async = true;
            s.src = '//munchkin.marketo.net/munchkin.js';
            s.onreadystatechange = function() {
            if (this.readyState == 'complete' || this.readyState == 'loaded') {
              initMunchkin();
            }
          };
          s.onload = initMunchkin;
          document.getElementsByTagName('head')[0].appendChild(s);
          })();
        `}
        </script>

        <script type="text/javascript">
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
            `}
        </script>
        {/* <!-- Google Tag Manager --> */}
        <script type="text/javascript">
          {`
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-5WMWJNZ');
            `}
        </script>

        {/* SoPro */}
        <script type="text/javascript">
          {`
                (function (o, u, t, b, a, s, e) {
                  window[b] = window[b] || {}; (e = e || [])['key'] = o; e['__obr'] = u.location.href; a = [];
                  u.location.search.replace('?', '').split('&').forEach(function (q) { if (q.startsWith(b) || q.startsWith('_obid')) e[q.split('=')[0]] = q.split('=')[1]; });
                  e['_obid'] = e['_obid'] || (u.cookie.match(/(^|;)\s*_obid\s*=\s*([^;]+)/) || []).pop() || 0;
                  for (k in e) { if (e.hasOwnProperty(k)) a.push(encodeURIComponent(k) + '=' + encodeURIComponent(e[k])); }
                  s = u.createElement('script'); s.src = t + '?' + a.join('&'); u.head.appendChild(s);
              })('9682ef8a-c580-4487-8037-633a9459a99a', document, 'https://plugin.sopro.io/hq.js', 'outbase')    
              `}
        </script>

        <script>
          {`
              (function (w, d, t) {
                _ml = w._ml || {};
                  _ml.eid = '81430';
                  _ml.cid = '301dcab4-aeda-4624-b3ba-5ac1d28746f1';
                  var s, cd, tag; s = d.getElementsByTagName(t)[0]; cd = new Date();
                  tag = d.createElement(t); tag.async = 1;
                  tag.src = 'https://ml314.com/tag.aspx?' + cd.getDate() + cd.getMonth();
                  s.parentNode.insertBefore(tag, s);
              })(window, document, 'script');
              `}
        </script>
        <script
          type="text/javascript"
          src="https://service.force.com/embeddedservice/5.0/esw.min.js"
        ></script>

        {/* Hotjar Tracking */}
        <script>
          {`
          (function(h,o,t,j,a,r){
              h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
              h._hjSettings={hjid:1503202,hjsv:6};
              a=o.getElementsByTagName('head')[0];
              r=o.createElement('script');r.async=1;
              r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
              a.appendChild(r);
          })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`}
        </script>

        <script
          referrerpolicy="no-referrer-when-downgrade"
          type="text/javascript"
          src=" https://www.onelink-edge.com/moxie.min.js"
          data-oljs="P294B-E0B2-7E1B-A9D7"
        ></script>
      </Helmet>
      <Loader />
      {hideNav ? null : (
        <>
          <SysBanner />
          <NavSwitcher slug={slug} />
        </>
      )}

      {children}
      <Footer />
    </LayoutContainer>
  )
}

const LayoutContainer = styled.div`
  opacity: 0;
  /* overflow-x: hidden; */
`

export default Layout
