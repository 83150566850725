import React from 'react'
import styled from 'styled-components';
import media from 'styles/media';
import colors from 'styles/colors';


export default function FooterMenu() {

const FooterMenuItems = [
  {
    heading: "COMPANY",
    items: [
      {
        title: "About Us",
        srcUrl: "https://vasion.com/company/",
      },
      {
        title: "Careers",
        srcUrl: "https://vasion.com/careers/",
      },
      {
        title: "Contact Us",
        srcUrl: "https://vasion.com/contact-us",
      },
      {
        title: "Giving Back",
        srcUrl: "https://vasion.com/vasion-gives-back",
      },
    ],
  },
  {
    heading: "PARTNERS",
    items: [
      {
        title: "Become A Partner",
        srcUrl: "https://partners.vasion.com/prm/English/s/applicant",
      },
      {
        title: "Partner Overview",
        srcUrl: "https://vasion.com/partner-overview",
      },
      {
        title: "MSP Partners",
        srcUrl: "https://vasion.com/msp-partner",
      },
    ],
  },
  {
    heading: "RESOURCES",
    items: [
      {
        title: "Blog",
        srcUrl: "/blog",
      },
      {
        title: "Resource Library",
        srcUrl: "/resources",
      },
      {
        title: "Admin Guides",
        srcUrl: "https://help.printerlogic.com/",
      },
      {
        title: "Knowledge Base",
        srcUrl: "https://kb.printerlogic.com/s/",
      },
    ],
  },
]

const footerMenu = FooterMenuItems.map((item) => (
    <ListContainer key={item.heading}>
      <Heading>{item.heading}</Heading>
      {item.items.map((menuItem, index) => (
        <Link key={index} href={menuItem.srcUrl}>
          {menuItem.title}
        </Link>
      ))}
    </ListContainer>
  ));

  return (
    <ColumnContainer>
        <ToolboxContainer>
            <AddressFooter>432 S. Tech Ridge Drive,St. George, Utah 84770 USA</AddressFooter>
            {/* <LegalText>Extra Text Box, W/Fries</LegalText> */}
        </ToolboxContainer>
        {footerMenu}
    </ColumnContainer>
  )
}


const ColumnContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    background-color: ${colors.grey750};
    gap: 3.472vw;
    padding:2.778vw 5.556vw;
    border-radius: 1.667vw;
    

    ${media.fullWidth}{
        gap: 50px;
        padding:40px 80px;
        border-radius: 24px;
    }
    ${media.tablet}{
        gap: 2.93vw;
        padding:2.93vw 3.906vw;
        border-radius: 2.344vw;
    }
    ${media.mobile}{
        flex-wrap: wrap;
        padding:10.667vw 6.4vw;
        border-radius: 6.4vw;
        gap: 7.667vw;
        margin: 0 6.4vw;
        justify-content: space-around;
    }
`
const AddressFooter = styled.address`
    font-family: Archivo;
    font-size: 1.25vw;
    line-height: 1.389vw;
    width: 14.931vw;
    font-weight: 500;
    letter-spacing: 0em;
    text-align: left;
    color: ${colors.grey200};
    font-style: normal;

    ${media.fullWidth}{
        font-size: 18px;
        line-height: 20px;
        width: 215px;
    }
    ${media.tablet}{
        font-size: 1.758vw;
        line-height: 1.953vw;
        width: 20.996vw;
    }
    ${media.mobile}{
        width: 30.6vw;
        font-size: 4.2vw;
        line-height: 5.333vw;
    }
`
// const LegalText = styled.p`
//     font-family: Archivo;
//     font-size: 1.25vw;
//     line-height: 1.389vw;
//     font-weight: 500;
//     letter-spacing: 0em;
//     text-align: left;
//     color: ${colors.grey200};

//     ${media.fullWidth}{
//         font-size: 18px;
//         line-height: 20px;
//     }
//     ${media.tablet}{
//         font-size: 1.758vw;
//         line-height: 1.953vw;
//     }
//     ${media.mobile}{
//         font-size: 4.8vw;
//         line-height: 5.333vw;
//     }
// `
const ToolboxContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2.5vw;

    ${media.fullWidth}{
        gap: 36px;
    }
    ${media.tablet}{
        gap: 3.516vw;
    }
    ${media.mobile}{
        gap: 4.267vw;
    }

`
const ListContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 0.694vw;

    ${media.fullWidth}{
        gap: 10px;
    }
    ${media.tablet}{
        gap: 0.977vw;
    }
    ${media.mobile}{
        gap: 2vw;
        width: 31.267vw;
    }
`
const Heading = styled.h3`
    font-family: Archivo;
    font-size: 1.042vw;
    line-height: 1.133vw;
    font-weight: 400;
    letter-spacing: 0em;
    text-align: left;
    color: ${colors.grey75};

    ${media.fullWidth}{
        font-size: 15px;
        line-height: 16px;
    }
    ${media.tablet}{
        font-size: 1.465vw;
        line-height: 1.594vw;
    }
    ${media.mobile}{
        font-size: 4.267vw;
        line-height: 4.352vw;
    }
`
const Link = styled.a`  
    font-family: Archivo;
    font-size: 1.111vw;
    line-height: 1.667vw;
    font-weight: 700;
    letter-spacing: 0em;
    text-align: left;
    color: ${colors.grey200};

    ${media.fullWidth}{
        font-size: 16px;
        line-height: 24px;
    }
    ${media.tablet}{
        font-size: 1.563vw;
        line-height: 2.344vw;
    }
    ${media.mobile}{
        font-size: 3.820vw;
        line-height: 6.4vw;
    }
`