import React from "react"
import styled from "styled-components"
import media from "styles/media"
import YTIcon from "../images/Footer/Youtube.svg"
import FacebookIcon from "../images/Footer/Facebook.svg"
import LinkedInIcon from "../images/Footer/LinkedIn.svg"
import TwitIcon from "../images/Footer/Twitter.svg"
import InstaIcon from "../images/Footer/Instagram.svg"

export default function SocialLinks() {
  const icons = [
    {
      img: YTIcon,
      link: "https://www.youtube.com/c/Printerlogic",
    },
    {
      img: FacebookIcon,
      link: "https://www.facebook.com/vasionprint",
    },
    {
      img: LinkedInIcon,
      link: "https://www.linkedin.com/company/vasionprint/",
    },
    {
      img: InstaIcon,
      link: "https://www.instagram.com/vasionprint/",
    },
    {
      img: TwitIcon,
      link: "https://x.com/VasionPrint",
    },
  ]

  const socialMediaIcons = icons.map(icon => (
    <div key={icon.link}>
      <a href={icon.link}>
        <SocialIcon src={icon.img} alt="social media icon" />
      </a>
    </div>
  ))

  return <FlexContainer>{socialMediaIcons}</FlexContainer>
}
const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 0.972vw;

  ${media.fullWidth} {
    gap: 14px;
  }
  ${media.tablet} {
    justify-content: flex-start;
    gap: 1.367vw;
  }
  ${media.mobile} {
    gap: 2.103vw;
  }
`

const SocialIcon = styled.img`
  ${media.mobile} {
    width: 7.477vw;
    height: 7.477vw;
  }
`
