import React, { useContext }  from 'react';

import PrimeNav from './PrimeNav';
import SecondaryNav from './SecondaryNav';
import { ScreenContext } from "../components/Providers/Screen"
import { useDocumentReady } from "utils/pageReady"

const DoubleNav = ({slug}) => {
const { desktop, fullWidth } = useContext(ScreenContext)
  useDocumentReady()
  return (
      <>
        {desktop || fullWidth ?
          <>
            <PrimeNav slug={slug}/>
            <SecondaryNav />
          </> :
          null
        }
      </>
    )
  }

export default DoubleNav